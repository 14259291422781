#logo span{
  font-size:30px;
}
#logo a{
  color:#FFFFFF;text-shadow:0px 1px 0px rgba(255, 255, 255,.3),0px -1px 0px rgba(0, 0, 0, .45);text-decoration: none;padding-left:15px
}
#headbar{
  height:45px;margin:0px;background:#E7EDF5;vertical-align:middle;
  background: url(http://www.opstool.com/static/img/header.png) repeat 0 0;
  border-bottom: 5px solid #729752;
}
#logo{
  float:left;vertical-align:middle;height:42px;line-height:42px;
}
#search_box{
  display:inline;
  float:left;
  height:42px;
  padding-left:16px;
}

#search_holder {height:42px; display:table;}
#search_holder div{*position:absolute; top:50%; left:0; display:table-cell; vertical-align:middle; width:100%;}
#search_holder #search_input {border:solid white 2.8px;width: 280px;}
#search_holder #search_button {border:solid white 1px;background-color:#729752;color:white;font-size:1.0em;padding:0 2ex;font-weight:bold;}

#search_result {
  margin-top:10px;
  margin-left:10px;
}

#toprightlinks{
  display:inline;
  float:right;
  height:48px;
  line-height:48px;
  vertical-align:middle;
}
#toprightlinks a{
  color:#FFF;
}
#toprightlinks .link1{
  color:#FFF;
  font-size:18px;
  padding-left:5px;
  padding-right:5px;
}
#toprightlinks .link2{
  color:#FFF;
  font-size:18px;
  border-left:2px dotted #CCC;
  padding-left:5px;padding-right:5px;
}
#toprightlinks .link3{
  color:#FFF;
  font-size:18px;
  border-left:2px dotted #CCC;
  padding-left:5px;
  padding-right:20px;
}
