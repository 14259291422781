

#tool_index {
    padding: 22px;
}

#tool_index a{
  border-bottom: 1px dotted;
}

#tool_index table{
    padding: 30px;
    border: 1px solid #ccc;
    -webkit-box-shadow: 0 0 5px rgba(0,0,0,.3);
    box-shadow: 0 0 5px rgba(0,0,0,.3);
    border-spacing: 0;
    border-radius: 5px;
    border-collapse:collapse;
    width:100%;
    font-family: "Lucida Grande", "Hiragino Sans GB", "Microsoft YaHei", "WenQuanYi Micro Hei", sans-serif;
  }
  #tool_index table thead {
    background:#eee;
    font-size:1.1em;
    font-weight:bold;
    border-bottom: 1px solid #ddd;
  }
  #tool_index table thead td{
    text-align:center;
    border:1px solid #D6D0E6;
  }
  #tool_index table td {
    height: 28px;
    padding-left:12px;
    border:1px solid #D6D0E6;
  }
  #tool_index table tr:nth-child(2n) {
    background-color: #F8F8F8;
  }
