
#mytable {
  border: 1px solid #ccc;
  -webkit-box-shadow: 0 0 5px rgba(0,0,0,.3);
  box-shadow: 0 0 5px rgba(0,0,0,.3);
  border-spacing: 0;
  border-radius: 5px;
  border-collapse:collapse;
  width:100%;
  font-family: "Lucida Grande", "Hiragino Sans GB", "Microsoft YaHei", "WenQuanYi Micro Hei", sans-serif;
}
#mytable thead {
  background:#eee;
  font-size:1.1em;
  font-weight:bold;
  border-bottom: 1px solid #ddd;
}
#mytable thead td{
  text-align:center;
  border:1px solid #D6D0E6;
}
#mytable td {
  height: 28px;
  padding-left:12px;
  border:1px solid #D6D0E6;
}
#mytable tr:nth-child(2n) {
  background-color: #F8F8F8;
}

.pager{
  font-size: 12px;
  text-align: right;
  font-weight: bold;
  /* margin: 0!important; */
  margin-top: 2px;
  margin-bottom: 2px;
  margin-right: 2px;
  height: 22px;
  float:left;
  padding: 8px 12px 0;
  border: 1px solid #E5E5E5;
  border-left: 0;
  color:#2953A6 !important;
}
#pageselect{
  color:#ccc;
  font-size: 12px;
  background-color: #EFEFEF;
}
#pagelist a:hover {
  color: #2953A6;
  font-size: 12px;
  background-color: #EFEFEF;
}
#pagepre{
  border: 1px solid #E5E5E5;
}
#pagenext{
  border: 1px solid #E5E5E5;
  color: #999 !important;
}
.pageempty{
  border: 1px solid #E5E5E5;
  color: #999 !important;
}
.hover{
  background-color:#EEF0EE;
}

#search_button {
  border: solid white 0.5px;
  background-color: #729752;
  color: white;
  font-size: 0.9em;
  padding: 0 2ex;
  outline-style: none ;
  /* font-weight: bold; */
}

#search_input {
  /* height : 18px; */
  width : 380px;
  outline-style: none ;
}