body{
  font-family: "Lucida Grande", "Hiragino Sans GB", "Microsoft YaHei", "WenQuanYi Micro Hei", sans-serif;
  margin:0px;
}
a:link,a:visited,a:hover {
  color:black;
  text-decoration:none;
}

#wapper{
  width:100%;
  height:auto;
  clear:both;
}
#maindiv{
  height:100%;
  clear:both;
  margin-left:210px;
 /* margin-right:210px;*/
}
#leftbar {
  width: 180px;
  margin:10px 0px 0px -198px;
  /*margin:10px 0px 0px 10px;*/
  float:left;
  position:relative;
  padding-top:0px;
  padding-left:5px;
  padding-right:0px;
  border-right: 2px dotted #CFDBEC;
/*
  border: 1px solid #ccc;
  -webkit-box-shadow: 0 0 5px rgba(0,0,0,.3);
  box-shadow: 0 0 5px rgba(0,0,0,.3);
*/
}
#menuhandler {
 float: right;
 padding: 0em;
 margin: 0em;
 margin-top: 0px;
 margin-right:0px;
}
#maincontent_wapper{
  font-family: "Lucida Grande", "Hiragino Sans GB", "Microsoft YaHei", "WenQuanYi Micro Hei", sans-serif;
  font-size : 0.9em;
  float:left;
  width:100%;
}
#maincontent{
 margin-right:20px;
 margin-top:10px;
 margin-bottom:10px; 
}
#divclear{
  clear: both;font-size: 0;line-height: 0;height: 0;overflow: hidden;visibility: hidden;
}
#friends_link {
  margin-top:0px;
  margin-bottom:0px;
  list-style-position: inside;
  margin-left: auto;
  margin-right: auto;
  width: 180px;
}
.friends_link_li {
  line-height:30px;
  font-size:0.9em;
}
.footlogo {
  margin-top:22px;
  text-align:center;
}
.footlogo span{
  color:#767676;
  font-size:10pt;
  font-family: "Lucida Grande", "Hiragino Sans GB", "Microsoft YaHei", "WenQuanYi Micro Hei", sans-serif;
}
.footlogo2 {
  margin-top:22px;text-align:right;
}
.footlogo2 span{
  color:#767676;
  font-size:10pt;
  font-family: "Lucida Grande", "Hiragino Sans GB", "Microsoft YaHei", "WenQuanYi Micro Hei", sans-serif;
}



/*鍒嗛〉*/
a#go-top{width:48px;height:48px;text-align:center;text-decoration:none;line-height:48px;color:#999;}
