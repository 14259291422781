#article_content table{
  border:1px solid;
  border-collapse:collapse;
  width:100%;
}

#article_content table tr {
  height: 28px;
  text-align:center;
  font-weight:normal;
  border:1px solid #666;
}

#article_content table tbody tr:nth-child(odd){
  background: #F8F8F8;
}

#article_content table td {
  border:1px solid #666;
}

#article_content table th {
  border:1px solid #666;
}

#article_content pre code{
  padding: 5px;
	border: 1px solid #ccc;
	font-size: 0.8em;
  display: block;
  background-color: rgb(250, 250, 250);
	font-family: "Consolas", "Monaco", "Bitstream Vera Sans Mono", "Courier New", Courier, monospace !important;
  overflow:auto;
}

#article_content pre ul,pre ol{
  padding-left:40px;
}

#article_view{
  font-size : 1.1em;
  height:100%;
  margin-right:0px;
  margin-bottom:30px;
  padding:12px 20px 16px 20px;
  border: 1px solid #ccc;
  -webkit-box-shadow: 0 0 5px rgba(0,0,0,.3);
  box-shadow: 0 0 5px rgba(0,0,0,.3);
}

#article_titile {
  margin-bottom:8px;
  margin-top:0px;color: #004dbc;
}

#article_meta {
  margin-top:-12px;
  margin-left:-20px;
  margin-right:-20px;
  padding-left:20px;
  padding-right:20px;
  padding-top:12px;
  background: #EEE;
  border-bottom: 1px solid #CCC;
}

#article_content {
  padding-top:8px;
}

#article_content a:link {
  color:black;
  text-decoration:underline;
}

#article_meta_info{
  border-top:1px dotted #CCC;
  margin-left:-28px;
  margin-right:-28px;
  padding-left:28px;
  padding-right:28px;
  margin-top:0px;
  margin-bottom:2px;
}
