.beian{
  margin: 0px auto;
}

.beian a{
  color: dimgray;
  font-size: small;
}

.beian_div{
  background-color:#eee;

}