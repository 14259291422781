
#tool_json {
    width: 100%;
}

#tool_json textarea {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
}

#tool_json pre {
    background-color: rgb(250, 250, 250);
	font-family: "Consolas", "Monaco", "Bitstream Vera Sans Mono", "Courier New", Courier, monospace !important;
    border: 1px solid #ccc;
    width: 1100px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
}