#tool_regex #main{
    text-align: left;
    border: 20px solid #e6e6e6;
    padding: 10px;
    padding-right: 15px;
    margin-left: auto;
    margin-right: auto;
    background-color: #5E5D5E;
    color: #ffffff;
    display:block;
}

#tool_regex .inputbox{
    background-color: #bbb;
    color: #000;
    padding: 10px;
    border: 1px solid #DDDDDD;
    font-family: monaco, courier;
    font-size: 13px;
    outline-style: none ;
}

#tool_regex #re{
    width: 96%;
    margin-right:60px;
}

#tool_regex #result{
    margin-left:530px;
}

#tool_regex .notice{
    border: 10px solid #779A73;
    background-color: #CDF3C9;
    color: #283A26;
    padding:20px;
    text-align:center
}

#tool_regex .error {
    top: 50px;
    background-color: #FDD2D2;
    border: 10px solid #D37979;
    color: #4D3838;
}

#tool_regex .match{
    color: #0066B3;
    background-color: #BFE4FF;
}

#tool_regex #match_line{
    border: 1px solid #DDDDDD;
    padding: 15px;
    background-color: #000000;
    color: #ffffff;
    margin-top: 2px;
    margin-bottom: 10px;
    font-family: monaco, courier;
    font-size: 14px;
}
#tool_regex #match_line span{
    white-space: pre-wrap;
}
#tool_regex #match_groups{
    border: 1px solid #DDDDDD;
    padding: 15px;
    background-color: #000000;
    color: #ffffff;
    margin-top: 2px;
    margin-bottom: 10px;
    font-family: monaco, courier;
}
#tool_regex #match_groups table{
    color: #ffffff;
    font-family: monaco, courier;
    border-spacing: 8px;
    border-collapse: collapse;
    border-color: gray;
}
#tool_regex #match_groups table td{
    border-spacing: 8px;
    border-color: gray;
}

#tool_regex #c2{
    margin-top: 10px;
    text-align: left;
    position: relative;
}
#tool_regex #c3{
    clear:both;
}
#tool_regex #test_str{
    float:left;
}
#tool_regex #test_str_wapper{

    display:block;
}
#tool_regex #qstr{
    width:500px;
    display: inline-block;
    font-size: 12px;
    font-family: monaco, courier;
}

#tool_regex h3{
    color: #6178D8;
    font-weight: normal;
    margin-bottom: 10px;
    font-size: 22px;
    margin-top: 22px;
    text-align: center;
}


#tool_regex pre {
    margin: 0px;
    padding: 0px;
}

#tool_regex #quickref {
    text-align: left;
    background-color: #e6e6e6;
    border: 10px solid #5E5D5E;
    font-size: 13px;
    padding: 5px;
    margin: 20px;
    font-family: futura, helvetica, arial;
}
