
#tool_sql {
    width: 100%;
}

#tool_sql textarea {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
}

#tool_sql pre {
    background-color: rgb(250, 250, 250);
	font-family: "Consolas", "Monaco", "Bitstream Vera Sans Mono", "Courier New", Courier, monospace !important;
    border: 1px solid #ccc;
    width: 1100px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
}

#tool_sql #copybox button {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
}

#tool_sql #copybox span {
    font-size: small;
    text-align: center;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
}

